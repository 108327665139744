<template>
  <div class="content">
    <p class="p1">尊敬的客户：</p>
    <p class="p2">
      根据
      <span>《中华人民共和国反恐怖主义法》</span>、
      <span>《全国人民代表大会常务委员会关于加强网络信息保护的决定》</span> 、
      <span>《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）</span>和
      <span>《电话用户真实身份信息登记规定》（工业和信息化部令第25号）</span>
      等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
    </p>
    <p class="p2">
      <span>如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。</span>
    </p>
    <p class="p3">
      为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
    </p>
    <p class="p4">(a)事先获得客户的明确授权；</p>
    <p class="p4">(b)根据有关的法律法规要求；</p>
    <p class="p4">(c)按照相关司法机关和/或政府主管部门的要求；</p>
    <p class="p4">(d)为维护社会公众的利益所必需且适当；</p>
    <p class="p4">(e)为维护我公司或客户的合法权益所必需且适当。</p>
    <p class="p5">中国联合网络通信有限公司</p>
  </div>
</template>

<script>
export default {
  name: 'InfoService'
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  text-align: left;
}

.content {
  padding: 15px;
  color: #000;
  line-height: 20px;

  .p1 {
    font-size: 13px;
    margin-top: 10px;
  }

  .p2 {
    text-indent: 2em;

    span {
      color: red;
    }
  }

  .p3 {
    text-indent: 2em;
  }

  .p4 {
    text-indent: 4em;
  }

  .p5 {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
